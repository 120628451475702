import { getData } from '../utils';
import BaseComponent from './base-component';

/**
 * Buttons for toggling classes and ARIA state.
 *
 * It's assumed that the toggling will mostly be used for (and is therefore
 * primarily built for) open/closed states.
 */
export default class Toggle extends BaseComponent {
  /**
   * The class name that's toggled.
   *
   * @type {string}
   */
  className;

  /**
   * Button text displayed when the toggled class name is active.
   *
   * @type {?string}
   */
  openedText;

  /**
   * Default button text. Only relevant if opened-text is used.
   *
   * @type {?string}
   */
  closedText;

  /**
   * Element to toggle button text on, if any.
   *
   * @type {?HTMLElement}
   */
  textTarget;

  /**
   * The element that has the class toggled on it.
   *
   * @type {HTMLElement}
   */
  target;

  /**
   * The `aria-controls` target, if any.
   *
   * @type {?HTMLElement}
   */
  ariaTarget;

  /**
   * If the class name should be removed when clicking outside the toggle
   * target.
   *
   * @type {boolean}
   */
  closeOnOutsideClick = false;

  init() {
    this.setToggleData();
    this.setToggleState();
    this.bindEvents();
  }

  /**
   * @returns {boolean}
   */
  get isOpen() {
    return this.target.classList.contains(this.className);
  }

  setToggleData() {
    const ariaControls = this.root.getAttribute('aria-controls');
    const ariaTarget = ariaControls
      ? document.getElementById(ariaControls)
      : null;

    const targetSelector = getData(this.root, 'toggle-on');
    let target = this.root;
    if (targetSelector === 'parent') {
      target = this.root.parentNode;
    } else if (targetSelector) {
      target = document.querySelector(targetSelector);
    } else if (ariaTarget) {
      target = ariaTarget;
    }

    this.ariaTarget = ariaTarget;
    this.className = getData(this.root, 'toggle-class');
    this.openedText = getData(this.root, 'opened-text');

    this.target = target;
    this.closeOnOutsideClick = getData(
      this.root,
      'close-on-outside-click',
      Boolean
    );

    if (this.openedText) {
      this.textTarget = this.selectSingle('.text') || this.root;
      this.closedText = this.textTarget.textContent;
    }
  }

  setToggleState() {
    const { isOpen } = this;
    if (this.ariaTarget) {
      this.root.setAttribute('aria-expanded', String(isOpen));
    }
    if (this.openedText) {
      this.textTarget.textContent = isOpen ? this.openedText : this.closedText;
    }
  }

  handleToggleClick = () => {
    this.target.classList.toggle(this.className);
    this.setToggleState();
  };

  /**
   * @param {MouseEvent} e - Click event.
   */
  handleOutsideClick = (e) => {
    // The toggle has the necessary 'flags' and the click target is not the
    // toggle button or the toggle target.
    if (
      this.ariaTarget &&
      this.isOpen &&
      !this.ariaTarget.contains(e.target) &&
      !this.root.contains(e.target)
    ) {
      this.target.classList.remove(this.className);
      this.setToggleState();
    }
  };

  bindEvents() {
    this.root.addEventListener('click', this.handleToggleClick);
    if (this.closeOnOutsideClick) {
      document.body.addEventListener('click', this.handleOutsideClick);
    }
  }
}
