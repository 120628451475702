import {
  debounce,
  getData,
  insertBefore,
  isButtonActivationKey,
  isNewTabEvent,
  setData,
} from '../utils';
import BaseComponent from './base-component';

const DROPDOWN_TITLE_ITEM_CLASS = 'menu-title-item';
const DROPDOWN_TRIGGER_ATTR_DATA_ATTR = 'dropdown-trigger-attr';
const TOP_LEVEL_PARENT_DATA_ATTR = 'top-level-parent';
const MOUSE_STATUS_DATA_ATTR = 'mouse';

/**
 * Navigation dropdown menus.
 *
 * Makes dropdown menus functional and accessible for touch and keyboard users,
 * who can't rely on a mouse hover state. This is done by treating the dropdown
 * parent links as buttons.
 *
 * The CSS must treat `a[aria-expanded='true']` just like `li:hover` for it
 * to work properly.
 *
 * @extends {ElementController}
 */
export default class Menu extends BaseComponent {
  /** @type {number} */
  dropdownCount = 0;

  /** @type {HTMLElement} */
  mainNav;

  /** @type {Array.<HTMLElement>} */
  dropdownNavs;

  /** @type {HTMLButtonElement} */
  menuToggle;

  /** @type {CSSStyleDeclaration} */
  menuToggleStyle;

  /** @type {Array.<HTMLAnchorElement>} */
  topLevelParentLinks = [];

  /** @type {boolean} */
  isToggledMenu = false;

  init() {
    this.mainNav = this.selectSingle('main-nav');
    this.dropdownNavs = this.selectAll('dropdown-nav');
    this.menuToggle = this.selectSingle('menu-toggle');

    this.dropdownNavs.forEach((nav) => {
      this.topLevelParentLinks.push(
        ...this.selectAll('ul[data-level="1"] > .has-children > a', nav)
      );
    });

    this.menuToggleStyle = window.getComputedStyle(this.menuToggle);
    this.checkMenuToggleState();
    window.addEventListener('resize', this.handleResize);

    // Make adjustments for functionality and accessibility that are only
    // relevant with JS available.
    this.dropdownify();

    // Close when pressing escape.
    this.mainNav.addEventListener('keydown', this.handleMainNavKeydown);

    // Handle outside clicks.
    document.body.addEventListener('click', this.handleBodyClick);
  }

  /**
   * Check if the menu is currently in its toggled state.
   */
  checkMenuToggleState = () => {
    this.isToggledMenu = this.menuToggleStyle.display !== 'none';
  };

  /**
   * Set or remove attributes on dropdown links depending on menu toggle state.
   *
   * When the menu is in its toggled state the whole tree is expanded without
   * any sub menu toggling, so the links should not be announced as buttons.
   *
   * @param {HTMLAnchorElement} a - Dropdown link.
   */
  setDropdownLinkAttr(a) {
    const attrs = getData(a, DROPDOWN_TRIGGER_ATTR_DATA_ATTR, JSON.parse);
    if (attrs) {
      if (this.isToggledMenu) {
        Object.keys(attrs).forEach((attr) => {
          a.removeAttribute(attr);
        });
      } else {
        Object.entries(attrs).forEach((attr) => {
          a.setAttribute(attr[0], attr[1]);
        });
      }
    }
  }

  /**
   * Make adjustments to menu items with children.
   *
   * - Adds the top level link as the first item in the dropdown.
   * - Makes top level links behave like buttons.
   * - Binds events to make keyboard focus work.
   */
  dropdownify() {
    this.topLevelParentLinks.forEach((a) => {
      this.dropdownCount += 1;
      const dropdownId = `nav-dropdown-${this.dropdownCount}`;

      const li = a.parentNode;
      const subMenu = this.selectSingle('.sub-menu', li);

      // Add the main link as the first sub menu item.
      // Do a shallow clone and set the text to get rid of any nested elements.
      const linkClone = a.cloneNode();
      linkClone.textContent = a.textContent;
      const titleItem = document.createElement('li');
      titleItem.classList.add(DROPDOWN_TITLE_ITEM_CLASS);
      if (linkClone.href === window.location.href) {
        titleItem.classList.add('current-item');
      }
      titleItem.append(linkClone);
      insertBefore(subMenu.firstChild, titleItem);

      // Make the link behave like a button and map it to the dropdown.
      subMenu.id = dropdownId;
      setData(
        a,
        DROPDOWN_TRIGGER_ATTR_DATA_ATTR,
        JSON.stringify({
          'role': 'button',
          'aria-controls': dropdownId,
          'aria-expanded': 'false',
        })
      );
      this.setDropdownLinkAttr(a);

      // Handle toggling.
      a.addEventListener('click', this.handleButtonizedLinkClick);
      a.addEventListener('keydown', this.handleButtonizedLinkKeydown);

      // Close when leaving the last link.
      li.setAttribute(`data-${TOP_LEVEL_PARENT_DATA_ATTR}`, '');
      const lastSubMenuLink = this.selectAll('a', subMenu).pop();
      lastSubMenuLink.addEventListener('blur', this.handleLastDropdownLinkBlur);

      // Close when pressing escape.
      subMenu.addEventListener('keydown', this.handleSubMenuKeydown);

      // Special 'click white listing' for mouse users.
      li.addEventListener('mouseenter', this.handleDropdownParentMouseEnter);
      li.addEventListener('mouseleave', this.handleDropdownParentMouseLeave);
    });
  }

  /**
   * Get a dropdown anchor element from the specified target.
   *
   * @param {HTMLElement} target - Target element; the link itself or its
   *   parent li.
   * @returns {?HTMLElement}
   */
  getDropdownLink(target) {
    return target.nodeName === 'A' ? target : this.selectSingle('a', target);
  }

  /**
   * Remove a dropdown's opened state.
   *
   * @param {HTMLElement} target - The dropdown link or its parent li.
   */
  removeOpenedState(target) {
    this.getDropdownLink(target).setAttribute('aria-expanded', 'false');
  }

  /**
   * Remove opened state from all dropdowns.
   *
   * @param {...HTMLElement} exclude - Items to exclude, should be dropdown
   *   links or their parent li.
   */
  removeAllOpenedStates(...exclude) {
    const activeLinks = this.topLevelParentLinks.filter(
      (link) => link.getAttribute('aria-expanded') === 'true'
    );
    if (activeLinks.length) {
      const excludesLinks = exclude
        .filter((item) => Boolean(item))
        .map((item) => this.getDropdownLink(item));
      activeLinks
        .filter((link) => !excludesLinks.includes(link))
        .forEach((link) => {
          this.removeOpenedState(link);
        });
    }
  }

  /**
   * Check if a dropdown is in its opened state.
   *
   * @param {HTMLElement} target - The dropdown link or its parent li.
   * @returns {boolean}
   */
  hasOpenState(target) {
    return (
      this.getDropdownLink(target).getAttribute('aria-expanded') === 'true'
    );
  }

  /**
   * Toggle a dropdown's opened state.
   *
   * @param {HTMLElement} target - The dropdown link or its parent li.
   */
  toggleOpenState(target) {
    const link = this.getDropdownLink(target);
    link.setAttribute(
      'aria-expanded',
      String(link.getAttribute('aria-expanded') === 'false')
    );
  }

  /**
   * Set focus to the specified element if currently focused on something
   * outside the main nav.
   *
   * @param {HTMLElement} rootNode - Node to check if outside of.
   * @param {HTMLElement} focusNode - Node to set focus to if relevant.
   */
  setFocusIfOutside = (rootNode, focusNode) => {
    setTimeout(() => {
      if (!rootNode.contains(document.activeElement)) {
        focusNode.focus();
      }
    });
  };

  /**
   * Check toggled menu state on resize.
   */
  handleResize = debounce(() => {
    this.checkMenuToggleState();
    this.topLevelParentLinks.forEach((a) => {
      this.setDropdownLinkAttr(a);
    });
  }, 200);

  /**
   * Remove opened state when leaving the last link in a dropdown.
   *
   * @param {FocusEvent} e - Blur event.
   */
  handleLastDropdownLinkBlur = (e) => {
    if (!this.isToggledMenu) {
      const topLevelItem = e.currentTarget.closest(
        `[data-${TOP_LEVEL_PARENT_DATA_ATTR}]`
      );
      // Don't close if tabbing backwards, i.e. if still inside the top level
      // parent item.
      if (e.relatedTarget && topLevelItem.contains(e.relatedTarget)) {
        return;
      }
      this.removeOpenedState(topLevelItem);
    }
  };

  /**
   * Prevent dropdown parent links from opening unless it's in a new tab, or
   * if hovering with a mouse.
   *
   * @param {MouseEvent} e - Click event.
   */
  handleButtonizedLinkClick = (e) => {
    const link = e.currentTarget;
    if (
      !this.isToggledMenu &&
      link.getAttribute('role') === 'button' &&
      !isNewTabEvent(e)
    ) {
      // Hovering with a mouse will reveal the dropdown without setting any
      // attributes to indicate its open state. Let the click pass through if
      // the submenu is fully visible - i.e. when the hover state is 'done'.
      const li = link.parentNode;
      if (getData(li, MOUSE_STATUS_DATA_ATTR)) {
        const subMenu = this.selectSingle('.sub-menu', li);
        const subMenuStyle = window.getComputedStyle(subMenu);
        if (parseFloat(subMenuStyle.opacity) === 1) {
          return;
        }
      }

      e.preventDefault();
      this.toggleOpenState(link);
    }
  };

  /**
   * Prevent dropdown parent links from opening unless it's in a new tab.
   *
   * @param {KeyboardEvent} e - Keydown event.
   */
  handleButtonizedLinkKeydown = (e) => {
    const link = e.currentTarget;
    if (
      link.getAttribute('role') === 'button' &&
      isButtonActivationKey(e.key) &&
      !isNewTabEvent(e)
    ) {
      e.preventDefault();
      // Trigger any click handlers
      link.click();
    }
  };

  /**
   * Close sub menu and focus the parent when pressing escape.
   *
   * @param {KeyboardEvent} e - Keydown event.
   */
  handleSubMenuKeydown = (e) => {
    if (!this.isToggledMenu && e.key === 'Escape') {
      const ul = e.currentTarget;
      const link = this.selectSingle('a', ul.parentNode);
      link.focus();
      this.toggleOpenState(link);
    }
  };

  /**
   * Set mouse data attribute for `handleButtonizedLinkClick()`.
   *
   * @param {MouseEvent} e - Mouseenter event.
   */
  handleDropdownParentMouseEnter = (e) => {
    setData(e.currentTarget, MOUSE_STATUS_DATA_ATTR, true);
  };

  /**
   * Set mouse data attribute for `handleButtonizedLinkClick()`.
   *
   * @param {MouseEvent} e - Mouseleave event.
   */
  handleDropdownParentMouseLeave = (e) => {
    setData(e.currentTarget, MOUSE_STATUS_DATA_ATTR, false);
  };

  /**
   * Close menu and focus toggle button when pressing escape.
   *
   * @param {KeyboardEvent} e - Keydown event.
   */
  handleMainNavKeydown = (e) => {
    if (
      this.isToggledMenu &&
      e.key === 'Escape' &&
      this.menuToggle.getAttribute('aria-expanded') === 'true'
    ) {
      this.menuToggle.focus();
      this.menuToggle.click();
    }
  };

  /**
   * Close open dropdowns when clicking outside them.
   *
   * @param {KeyboardEvent} e - Keydown event.
   */
  handleBodyClick = (e) => {
    if (!this.isToggledMenu) {
      const parentItem = e.target.closest(
        `[data-${TOP_LEVEL_PARENT_DATA_ATTR}]`
      );
      // Exclude any current one
      this.removeAllOpenedStates(parentItem);
    }
  };
}
